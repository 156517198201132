var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardTemplate',[_c('Layout',[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"font-32 mg-b-7 mg-t-1 font-weight-bold"},[_vm._v(" ปัจจัยประเมินความพร้อม ")]),(!_vm.loading)?_c('p',{staticClass:"text-dark font-20 mg-y-5"},[_vm._v(" สำหรับ"+_vm._s(_vm.getNameAsseement(_vm.assessmentId))+" ")]):_vm._e(),_c('p',{staticClass:"text-grey text-center font-18 mg-y-5"},[_vm._v(" (กรุณาเลือกด้านเพื่อทำแบบประเมิน TERAK) ")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"mg-t-3 h-min-180"},_vm._l((_vm.categoryList),function(item,index){return _c('div',{key:item.id,staticClass:"factor--avatar bordered ai-center border-2 w-100 cr-pointer mg-b-3 rounded pd-5",class:[
            { doing: item.success > 0 && item.success != item.question },
            { success: item.success == item.question },
          ],on:{"click":function($event){item.question == item.success
              ? _vm.$router.push(
                  `/level?assessment=${_vm.assessmentId}&cate=${
                    item.id
                  }&question=${
                    item.questionsId[item.success - 1].questionId
                  }&number=${item.success}`
                )
              : _vm.$router.push(
                  `/level?assessment=${_vm.assessmentId}&cate=${
                    item.id
                  }&question=${
                    item.questionsId[item.success].questionId
                  }&number=${item.success + 1}`
                )}}},[_c('div',{staticClass:"circle-box",class:[
              { doing: item.success > 0 },
              { success: item.success == item.question },
            ]},[_c('img',{staticClass:"mw-100",attrs:{"src":require(`@/assets/assessment${index + 1}.png`),"alt":""}})]),_c('div',{staticClass:"w-100",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"mg-t-7 pd-l-5"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"pd-l-5"},[_c('el-row',{attrs:{"gutter":20,"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"xs":19,"span":20,"offset":0}},[_c('el-progress',{staticClass:"w-100 mg-t-7",attrs:{"show-text":false,"percentage":_vm.getPercen(item.success, item.question),"status":item.success == item.question ? 'success' : null}})],1),_c('el-col',{class:[
                    { 'text-primary': item.success > 0 },
                    { 'text-success': item.success == item.question },
                  ],attrs:{"xs":5,"span":4,"offset":0}},[_vm._v(_vm._s(item.success)+"/"+_vm._s(item.question))])],1)],1)])])}),0),_c('div',{staticClass:"mg-t-3 text-center"},[(_vm.allowSentAssessment && !_vm.loading)?_c('el-button',{staticClass:"wid220",attrs:{"type":"primary"},on:{"click":function($event){_vm.confirmDialog = !_vm.confirmDialog}}},[_vm._v("ส่งแบบประเมิน")]):_vm._e()],1),_c('el-dialog',{staticClass:"small",attrs:{"visible":_vm.confirmDialog},on:{"update:visible":function($event){_vm.confirmDialog=$event}}},[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"mg-t-5 font-weight-bold"},[_vm._v(" คุณต้องการยืนยันการส่งแบบประเมิน? ")]),_c('p',{staticClass:"text-grey mg-y-6"},[_vm._v("หากคุณกดส่งแบบประเมินแล้ว")]),_c('p',{staticClass:"text-grey mg-y-6"},[_vm._v(" จะไม่สามารถกลับมาแก้ไขข้อมูลที่กรอกไปแล้วได้ ")])]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"is-flex js-between ai-center"},[_c('a',{staticClass:"text-grey-light font-20 mg-l-7",on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v("ยกเลิก")]),_c('div',[_c('el-button',{attrs:{"id":"sendAssessment","type":"primary","loading":_vm.loadingConfirm},on:{"click":_vm.confirmAssessment}},[_vm._v("ยืนยัน")])],1)])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }